import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { getValidLangKey, getBrowserLanguage } from "ptz-i18n";
import languages from "../data/languages";
import moment from "moment";
import "moment/locale/pt";
import "moment/locale/en-gb";

const getRedirectLanguage = () => {
    const { langs, defaultLangKey } = languages;
    const browserLanguage = getBrowserLanguage();
    return getValidLangKey(langs, defaultLangKey, browserLanguage);
};

const Index = () => {
    useEffect(() => {
        const { defaultLangKey } = languages;
        const urlLang = getRedirectLanguage();

        if (urlLang) {
            if (urlLang === "pt") {
                moment.locale("pt");
                // moment.updateLocale("pt");
            } else {
                moment.locale("en-gb");
                // moment.updateLocale("en-gb");
            }
        } else {
            moment.locale("en-gb");
            // moment.updateLocale("en-gb");
        }

        navigate(`/${urlLang ? urlLang : defaultLangKey}/`);
    }, []);

    return null;
};

export default Index;
